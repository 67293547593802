import React from "react";
import styled from "styled-components";
import Container from "./styles/Container";
import { brandColor2, white } from "./styles/Colors";
import { Headline80, Headline40 } from "./styles/Headings";
import OutlinedText from "./styles/OutlinedText";
import PrimaryButton from "./PrimaryButton";
import { mediaMedium } from "./styles/variables";

import imageDecoration from "../images/23-z-decoration-lines.svg";
import { Link } from "gatsby";

const Wrapper = styled.div`
  overflow: hidden;
  background-color: ${brandColor2};
  padding: 156px 0;

  @media (${mediaMedium}) {
    padding: 124px 0;
  }
`;

const Grid = styled(Container)`
  @media (${mediaMedium}) {
    position: relative;
    display: grid;
    grid-template-columns: calc(100% * 3 / 12) calc(100% * 5 / 12);
    grid-gap: calc(100% * 1 / 12);
    justify-content: space-between;
  }
`;

const Background = styled.div`
  pointer-events: none;

  @media (${mediaMedium}) {
    position: absolute;
    top: -380px;
    transform: translateY(20px);
    bottom: -124px;
    left: 0;
    background: url("${imageDecoration}") top left no-repeat;
    background-size: 100% 110%;
    width: calc(100% * 6.5 / 12);
  }
`;

const Offset = styled.div`
  @media (${mediaMedium}) {
    padding-top: 188px;
  }
`;

const Title = styled(Headline40)`
  line-height: 33px;
  color: ${white};
  margin: 34px 0;

  @media (${mediaMedium}) {
    line-height: 52px;
  }
`;

const Row = styled.div`
  margin-top: 40px;

  @media (${mediaMedium}) {
    margin: 0;
  }
`;

const HeroGetYourShop = () => {
  return (
    <Wrapper>
      <Grid>
        <Background />
        <div>
          <Headline80 theme={{ color: white }}>
            GET YOUR <br />{" "}
            <OutlinedText
              color={white}
              component={Headline80}
              as="span"
              fill={brandColor2}
            >
              SHOP
            </OutlinedText>
          </Headline80>
        </div>
        <Offset>
          <Title as="p">
            No developer <br /> work required
          </Title>
          <Title as="p">
            AI-driven <br /> personalization
          </Title>
          <Title as="p">
            Best price on the <br /> web offers
          </Title>
          <Title as="p">Low monthly cost</Title>
          <Row>
            <PrimaryButton
              attrs={{
                to: "/contact/",
                state: { from: "publisher" },
                as: Link
              }}
            >
              Take Me There
            </PrimaryButton>
          </Row>
        </Offset>
      </Grid>
    </Wrapper>
  );
};

export default HeroGetYourShop;
