import React from "react";
import HeroTwoColumns from "../HeroTwoColumns";
import { gray4 } from "../styles/Colors";
import DisplayWithOverflow from "../DisplayWithOverflow";
import PagePadding from "../styles/PagePadding";
import HeroContentSnippet from "../HeroContentSnippet";
import HeroGetYourShop from "../HeroGetYourShop";
import MoreWaysToEarn from "../MoreWaysToEarn";
import MoveInDiv from "../MoveInDiv";

// Images
import imageToolkitDesktop from "../../images/22-commerce-toolkit.png";
import imageToolkitMobile from "../../images/22-commerce-toolkit-mobile.png";

const toolkitMediaImages = [
  {
    src: imageToolkitDesktop,
    width: 897,
    height: 818,
    alt: "YOUR COMMERCE TOOLKIT"
  },
  {
    src: imageToolkitMobile,
    width: 375,
    height: 449,
    alt: "YOUR COMMERCE TOOLKIT"
  }
];

const PublishersSelfShopsDefer = () => {
  return (
    <>
      <HeroTwoColumns
        background={gray4}
        reverse={true}
        columns="50% 40%"
        noPadding={true}
        padding="274px 0 0"
        paddingMedia="143px 0 0"
      >
        <MoveInDiv>
          <DisplayWithOverflow
            theme={{
              css: `width: 958px;`,
              transform: `translateX(calc(-100% * 2 / 12))`
            }}
            image={toolkitMediaImages}
          />
        </MoveInDiv>
        <PagePadding>
          <HeroContentSnippet
            title="Your commerce toolkit"
            noTrail={true}
            link={false}
            padding="0 0 216px"
          >
            <p>
              You'll get access to our entire 100% merchant-direct deal
              catalogue, and a custom storefront personalized to your brand.
              Plus, take advantage of a library of commerce content to promote
              your favorite deals.
            </p>
          </HeroContentSnippet>
        </PagePadding>
      </HeroTwoColumns>

      <HeroGetYourShop />

      <MoreWaysToEarn />
    </>
  );
};

export default PublishersSelfShopsDefer;
